/* body {
  background: url("./shared/images/1440.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

@media only screen and (max-width: 590px) {
  body {
    background: url("./shared/images/586.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }
} */

#root {
  background: url("./shared/images/1440.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

@media only screen and (max-width: 590px) {
  #root {
    background: url("./shared/images/586.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }
}

.connect-container {
  display: flex;
  height: 300px;
  justify-content: flex-end;
  /* padding: 0 180px; */
  padding: 0 130px;
}
@media only screen and (min-width: 1080px) {
  .connect-container {
    padding: 50px 200px;
  }
}

@media screen and (max-width: 1024px) {
  .connect-container {
    justify-content: center;
    padding: 0;
  }
}
@media screen and (max-width: 588px) {
  .connect-container {
    transform: translateY(200px);
  }
}

.connect-card {
  display: flex;
  justify-content: center;
  min-width: 400px;
  align-items: center;
  flex-direction: column;
  /* margin-top: 100px; */
  /* margin-top: 20px; */
}

@media screen and (max-width: 380px) {
  .connect-card {
    max-width: 250px;
  }
}
.connect-card span {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.9);
  line-height: 1.2;
  margin-bottom: 2rem;
  padding: 10px;
}
@media screen and (max-width: 380px) {
  .connect-card span {
    max-width: 270px;
    font-size: 1rem;
    padding: 5px;
  }
}
@media screen and (max-width: 280px) {
  .connect-card span {
    max-width: 270px;
    font-size: 0.8rem;
  }
}

.mint-container {
  display: flex;
  height: 300px;
  justify-content: flex-end;
  padding: 0 130px;
}

@media only screen and (min-width: 1080px) {
  .mint-container {
    padding: 0 200px;
  }
}
@media screen and (max-width: 1024px) {
  .mint-container {
    transform: translateY(100px);
  }
}

@media screen and (max-width: 588px) {
  .mint-container {
    transform: translateY(200px);
  }
}

@media screen and (max-width: 470px) {
  .mint-container {
    padding: 0px;
    transform: translateY(150px);
    justify-content: center;
  }
}
@media screen and (max-width: 350px) {
  .mint-container {
    max-width: 280px;
  }
}

.mint-card {
  display: flex;
  justify-content: center;
  min-width: 350px;
  align-items: center;
  flex-direction: column;
  /* margin-top: 100px; */
  background-color: #be1000;
  -webkit-animation: glow 2s linear infinite;
  animation: glow 3s linear infinite;
  border: 2px solid black;
  height: 100%;
}
@media screen and (max-width: 350px) {
  .mint-card {
    max-width: 260px;
  }
}

.mint-card span {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  padding: 5px;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.9);
}

@-webkit-keyframes glow {
  0% {
    box-shadow: 0 0 0 0 #bf1000;
  }

  50% {
    box-shadow: 0 0 30px 0 #bf1000;
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0 0 #bf1000;
  }

  50% {
    box-shadow: 0 0 30px 0 #bf1000;
  }
}

.btn {
  background: none;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
  border: 2px solid black;
  outline: none;
  cursor: pointer;
  width: 250px;
}

@media screen and (max-width: 350px) {
  .btn {
    width: 200px;
  }
}

.btn-glow {
  background: #bf1000;
  color: #fff;
  font-size: 1.6em;
  font-weight: 600;
  text-transform: uppercase;
  padding: 16px 34px;
  box-shadow: 0 0 0 0 #bf1000;
  -webkit-animation: glow 2s linear infinite;
  animation: glow 2s linear infinite;
}
@media screen and (max-width: 380px) {
  .btn-glow {
    font-size: 1em;
  }
}

.btn-mint {
  background: black;
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px 9px;
  margin-left: 15px;
  margin-bottom: 0;
  height: 100%;
}

.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
}
